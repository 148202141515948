:root {
  --color-smooth-white: #F5F5F5;
  --color-white: white;
  --color-soothing-dark-blue: #31525B;
  --color-blazing-yellow: #FEEAA1;
  --color-summer-yellow: #FDD45B;
  --color-sunrise-orange: #FDB95D;
  --color-autumn-orange: #FFA101;
  --color-sunset-orange: #FF7D65;
  --color-baby-blue-pink: #B3DEE5;
  --color-leaf-green: #C0DE3A;
  --color-forest-green: #A9D168;
}

html,
body {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
  background: var(--color-smooth-white);
}

.header {
  background-color: var(--color-white);
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
}
.footer {
  background-color: var(--color-soothing-dark-blue);

}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 200px;
}


.footer-link:hover {
  color: var(--color-autumn-orange);
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  height: 100vh;
  background-color: var(--color-baby-blue-pink);
  padding-top: 50px;
}
.Box-Design{
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 20px;
  overflow: hidden;
}
.paragraph-small {
  font-size: 12px;
}
h2 {
  color: #333;
  font-size: 2em;
  margin-bottom: 0.5em;
}


ul {
  list-style-type: none; /* removes default list styling */
  padding-left: 20px; /* adds some padding inside the list */
}

ul li {
  margin-bottom: 5px; /* adds space between list items */
}

ul li a {
  text-decoration: none;
  color: #1a0dab;
}

ul li a:hover {
  text-decoration: underline;
}

.padding-left-right-20{
  padding-right: 20px;
}
.padding-bottom-20{
  padding-bottom: 20px;
}

.padding-top-20{
  padding-top: 20px;
}
.Box-Design-xxs{
  height: 100px;
}
.A-main {
  padding: 20px;

}

.B-history {
  max-height: max-content;
  overflow-y: auto;
}


.C-option {

}

.input-row {

  margin-bottom: 15px;
}


.input-label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.custom-select {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-input {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.history-container{
  width: 100%;
}

.history-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

.custom-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  margin-left: 20px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #0056b3;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.space-below {
  margin-bottom: 10px;
}




.footer-title {
  color: #FAE6B1;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-link {
  color: #FAE6B1;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header_l {
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.social-links_l {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.footer-link_l:hover {
  color: #FFA101;
}

.App_l {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.footer_l {
  background-color: #31525B;
  padding: 20px 0;
  width: 100%;
}

.App-logo_l {
  height: 40vmin;
  pointer-events: none;
}

.container_l {
  height: 100vh;
  background-color: #B3DEE5;
  padding-top: 50px;
}

.Box-Design_l {
  height: 95%;
  background-color: #fafafa;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 20px;
  overflow: hidden;
}

.padding-bottom-20_l {
  padding-bottom: 20px;
}


.A-main, .C-option_l {
  display: flex;
  flex-direction: column;
}
.B-history_l {
  overflow-y: auto;
  display: flex;
  flex-direction: column;

}
.titleStyle-home-l {
  font-size: 32px;
  font-weight: bold;
}

.textStyle-home-l {
  font-size: 18px;
}

.textStyle-home-xl {
  font-size: 21px;
}
.B-history_xl {
  overflow-y: auto;
  display: flex;
  flex-direction: column;

}

.input-row_l {
  margin-bottom: 15px;
}

.input-label_l {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.custom-select_l {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-input_l {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.history-container_l {
  display: flex;
  align-items: flex-start;
}

.history-wrapper_l {
  height: 90%;
  overflow-y: auto;
}

.history-wrapper_xl {
  height: 90%;
  overflow-y: auto;
}

.custom-button_l {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button_l:hover {
  background-color: #0056b3;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo_l {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header_l {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link_l {
  color: #61dafb;
}

.space-below_l {
  margin-bottom: 10px;
}

.footer_l {
  background-color: #31525B;
  margin-top: auto;
  padding: 20px 0;
}

.fixed-footer_l {
  bottom: 0;
  width: 100%;
}

.footer-title_l {
  color: #FAE6B1;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-link_l {
  color: var(--color-white);
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}
.footer-logo {
  width: auto;  /* Change this value to the desired width */
  height: 100px; /* This ensures the aspect ratio is maintained */
}

@keyframes App-logo-spin_l {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}